import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"
import Header from "../components/Header"
import Features from "../components/Features"
import InstallNow from "../components/InstallNow"
import Feedback from "../components/Feedback"
import Footer from "../components/Footer"
import AboutUs from "../components/AboutUs"
import Steps from "../components/Steps"
import FAQ from "../components/FAQ"

const IndexPage = () => (
  <Layout>
    <SEO title="HeyPixie - Dein digitales Inventar" keywords={[`startup`, `gebraucht verkaufen`, `inventar`, 'digital']} />
    <Navbar />
    <Header />
    <Features />
    <InstallNow />
    <Steps />
    <FAQ />
    <AboutUs />
    <Feedback />
    <Footer />
  </Layout>
)

export default IndexPage
