import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { DARK_BLUE, WRAPPER_WIDTH, TABLET_MIN_WIDTH } from './config'
import PrimaryButtonBlue from './PrimaryButtonBlue'
import * as line from '../images/line1.png'
import * as berlin from '../images/berlin.jpg'

const AboutUs = () => {
  return (
    <OuterWrapper>
      <Wrapper>
        <Box>
          <Headline>
            Über HeyPixie
          </Headline>
          <Text>
            Wir sind ein Berliner Startup und entwickeln eine 
            Inventory Management Application für Privatpersonen. 
            Das tun wir, um unseren Usern und Userinnen zu einer neuen 
            Konsumkompetenz zu verhelfen. Das heißt zum einen, 
            das eigene Konsumverhalten besser zu verstehen und 
            zum anderen aktiv nachhaltiger zu konsumieren. Beides 
            ist mit HeyPixie möglich. Das ist wichtig, da unser 
            Leben nicht durch Konsum bestimmt sein darf und weil 
            das heutige Ausmaß an Konsum zu immensen Umweltbelastungen 
            führt. Daran muss sich etwas ändern.
          </Text>
          <Line src={line} />
          <Img src={berlin} />
        </Box>
      </Wrapper>
    </OuterWrapper>
  )
}

const OuterWrapper = styled.section`
`

const Wrapper = styled.div`
  max-width: ${WRAPPER_WIDTH}px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 80px 32px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    padding: 32px 24px;
  }
`

const Box = styled.div`
  max-width: 560px;
  text-align: center;
`

const Headline = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    font-size: 20px;
    line-height: 28px;
  }
`

const Text = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`

const Line = styled.img`
  width: 120px;
  margin-bottom: 32px;
  margin-top: 16px;
`

const Img = styled.img`
  max-width: 100%;
`

export default AboutUs
