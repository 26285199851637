import React from "react"
import styled from "styled-components"

import { TABLET_MIN_WIDTH } from './config'
import Step, { Headline, List, ListItem, Para } from "./Step"
import * as newButton from '../images/screenshots/new_button.png'
import * as selling from '../images/screenshots/selling2.png'
import * as manageLists from '../images/screenshots/manage_lists.png'
import * as metrics from '../images/screenshots/metrics2.png'
import * as singleItem from '../images/screenshots/single_item.png'

const Steps = () => {
  return (
    <React.Fragment>
      <MainHeadline>So funktioniert HeyPixie</MainHeadline>
      <Step isFirst previewImg={newButton}>
        <Headline>1. Gegenstände digitalisieren</Headline>
        <Para>
          <strong>HeyPixie</strong> bietet dir viele komfortable Wege,
          um Gegenstände in deinem digitalen Inventar zu erfassen. 
          Informationen kannst du automatisch auslesen, indem du ...
        </Para>
        <List>
          <ListItem>den Gegenstand fotografierst</ListItem>
          <ListItem>eine URL zum Gegenstand eingibst</ListItem>
          <ListItem>den Kaufbeleg fotografierst</ListItem>
          <ListItem>die Bestätigungsmail des Online-Kaufs an HeyPixie weiterleitest</ListItem>
        </List>
      </Step>
      <Step isRight previewImg={singleItem}>
        <Headline>2. Gegenstände anzeigen</Headline>
        <Para>
          Für jeden Gegenstand legt <strong>HeyPixie</strong> einen Eintrag mit
          allen Informationen an, die wichtig sind. Diese werden automatisch kategorisiert
          und Informationen können von dir manuell ergänzt werden.
        </Para>
        <List>
          <ListItem>Foto & Beschreibung des Gegenstands</ListItem>
          <ListItem>Kaufdatum & Originalpreis</ListItem>
          <ListItem>Marke, Farbe & Größe</ListItem>
          <ListItem>Alle Informationen an einem Ort und digital verfügbar</ListItem>
        </List>
      </Step>
      <Step previewImg={manageLists}>
        <Headline>3. Gegenstände verwalten</Headline>
        <Para>
          Erfasste Gegenstände kannst du in Listen verwalten. Das erleichtert vieles:
        </Para>
        <List>
          <ListItem>
            Alles, was du <strong>verleihen</strong> willst, kommt in eine Liste, die du mit ausgewählten
            Freunden teilst.
          </ListItem>
          <ListItem>
            Alles, was du <strong>mit auf eine Reise nehmen</strong> willst, fügst du einer Liste hinzu.  
            Und fertig ist deine Packliste.
          </ListItem>
          <ListItem>
            Dein Zeug verteilt sich auf deine Wohnung und den Keller deiner Eltern? 
            Lege <strong>für jeden Standort eine Liste</strong> an und füge 
            Gegenstände entsprechend hinzu.
          </ListItem>
        </List>
      </Step>
      <Step isRight previewImg={selling}>
        <Headline>4. Gegenstände verkaufen</Headline>
        <Para>
          Erfasste Gegenstände kannst du smart & einfach über verschiedene
          Portale zum besten Preis weiterverkaufen.
        </Para>
        <List>
          <ListItem>Gegenstände einzeln oder als Bündel auf Portalen
           wie eBay oder Kleiderkreisel an andere Privatpersonen verkaufen 
           oder von Anbietern wie Zalando Wardrobe ankaufen lassen</ListItem>
          <ListItem>Wiederverkaufspreise der beliebtesten Portale vergleichen 
          und den besten Preis auswählen</ListItem>
          <ListItem>Anzeige für deinen Gegenstand mit Foto, Text & Gebrauchszustand 
            direkt aus der App online stellen</ListItem>
        </List>
      </Step>
      <Step isLast previewImg={metrics}>
        <Headline>5. Eigenes Verhalten verstehen</Headline>
        <Para>
          Um dein Konsumverhalten immer im Blick zu haben, erstellt HeyPixie eine individuelle 
          Analyse für dich.
        </Para>
        <List>
          <ListItem>Auflistung deiner Neu-Käufe & Gebraucht-Verkäufe</ListItem>
          <ListItem>Anzeige des Trends, wie viele Gegenstände du besitzt</ListItem>
          <ListItem>Berechnung der durchschnittlichen Lebensdauer deiner Gegenstände</ListItem>
          <ListItem>Individuelle Nachhaltigskeitsbilanz auf Basis deiner konsumierten Marken</ListItem>
        </List>
      </Step>
    </React.Fragment>
  )
}

const MainHeadline = styled.h2`
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-top: 64px;
  margin-bottom: 48px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    text-align: center;
  }
`

export default Steps
