import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { DARK_BLUE, GREEN, WRAPPER_WIDTH, TABLET_MIN_WIDTH } from './config'
import PrimaryButtonBlue from './PrimaryButtonBlue'
import Phone from './Phone'
import * as overview from '../images/screenshots/overview.png'

const InstallNow = () => {
  return (
    <OuterWrapper>
      <Wrapper>
        <RightColumn>
          <Headline>
            Entdecke jetzt unsere<br/>
            kostenlose HeyPixie App!
          </Headline>
          <Text>
            Mach deine Gegenstände smart. Erstelle mit HeyPixie dein
            digitales Inventar und verkaufe, verleihe und packe in
            Sekundenschnelle.
          </Text>
          <PrimaryButtonBlue to='/app'>
            Zur App
          </PrimaryButtonBlue>
        </RightColumn>
        <LeftColumn>
          <Phone image={overview} showBlock />
        </LeftColumn>
      </Wrapper>
    </OuterWrapper>
  )
}

const OuterWrapper = styled.header`
  background-color: ${GREEN};
`

const Wrapper = styled.div`
  max-width: ${WRAPPER_WIDTH}px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 64px 32px 64px 32px;
  color: white;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    display: block;
    padding: 32px 24px 32px 24px;
    text-align: center;
  }
`

const LeftColumn = styled.div`
  width: 30%;
  margin-right: 96px;
  order: -1;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
    margin-top: 32px;
    margin-right: 0;
  }
`

const RightColumn = styled.div`
  width: 60%;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
  }
`

const Headline = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    font-size: 28px;
    line-height: 36px;
  }
`

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 64px;
  strong {
    font-weight: 700;
  }
`

export default InstallNow
