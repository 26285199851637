import React from "react"
import styled from "styled-components"

import { WRAPPER_WIDTH, GREY_90, TABLET_MIN_WIDTH, GREEN } from './config'
import Phone from './Phone'

const Step = ({ children, previewImg, isRight, isLast, isFirst }) => {
  return (
    <OuterWrapper>
      <Wrapper>
        <Line isLast={isLast} isFirst={isFirst} />
        <ColumnWrapper>
          <TextColumn isLeft={!isRight}>
            {children}
          </TextColumn>
          <Preview isLeft={isRight}>
            <PhoneWrapper><Phone showBlock autoWidth image={previewImg} /></PhoneWrapper>
          </Preview>
        </ColumnWrapper>
      </Wrapper>
    </OuterWrapper>
  )
}

const Line = styled.div`
  position: relative;
  align-self: stretch;
  background-color: ${GREY_90};
  width: 6px;
  order: 1;
  flex-grow: 1;
  flex-basis: 6px;
  flex-shrink: 0;

  &:before {
    content: '';
    display: ${({ isLast, isFirst }) => isLast || isFirst ? 'block' : 'none'};
    position: absolute;
    background-color: white;
    ${({ isLast }) => isLast && `bottom: 0;`}
    ${({ isFirst }) => isFirst && `top: 0;`}
    left: 0;
    ${({ isLast }) => isLast && `height: 70%;`}
    ${({ isFirst }) => isFirst && `height: 30%;`}
    width: 100%;
  }

  &:after {
    content: '';
    background-color: white;
    position: absolute;
    border-radius: 100%;
    border: 5px solid ${GREEN};
    width: 14px;
    height: 14px;
    left: -8px;
    top: 30%;
  }

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    order: 0;
    margin-right: 24px;
    margin-left: 24px;

    &:before {
      ${({ isLast }) => isLast && `height: 100%;`}
      ${({ isFirst }) => isFirst && `height: 4px;`}
    }

    &:after {
      top: 4px;
    }
  }
`

const OuterWrapper = styled.section`
`

const ColumnWrapper = styled.div`
  display: contents;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    display: block;
    flex-grow: 1;
    padding-right: 24px;
  }
`

const Wrapper = styled.div`
  max-width: ${WRAPPER_WIDTH}px;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
`

const TextColumn = styled.div`
  width: calc(50% - 3px);
  flex-grow: 1;
  padding: 0 32px;
  padding-bottom: 64px;
  box-sizing: border-box;
  order: ${({ isLeft }) => isLeft ? 0 : 2};

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
    padding: 0;
  }
`

const Preview = styled.div`
  padding: 0 32px;
  box-sizing: border-box;
  flex-grow: 1;
  margin-top: -32px;
  width: calc(50% - 3px);
  order: ${({ isLeft }) => isLeft ? 0 : 2};
  padding-left: ${({ isLeft }) => isLeft ? 32 : 96}px;
  padding-right: ${({ isLeft }) => isLeft ? 96 : 32}px;
  padding-bottom: 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
    padding: 0;
    margin-top: 0;
  }
`

const PhoneWrapper = styled.div`
  margin: 0 auto;
  width: 300px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
  }
`

export const Headline = styled.h3`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const List = styled.ul`
  font-size: 16px;
  line-height: 24px;
`

export const ListItem = styled.li`
  margin-bottom: 16px;
  padding-left: 64px;
  position: relative;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    padding-left: 32px;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    border: 4px solid ${GREY_90};
    left: 32px;
    top: 4px;

    @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
      left: 0;
    }
  }
`

export const Para = styled.p`
  margin: 32px 0;
  font-size: 18px;
  line-height: 26px;
`

export default Step
