import React from "react"
import styled from "styled-components"

import { TABLET_MIN_WIDTH } from './config'
import Feature, { Headline, List, ListItem } from "./Feature"
import * as selling from '../images/screenshots/selling_bulk.png'
import * as manageLists from '../images/screenshots/manage_lists.png'
import * as metrics from '../images/screenshots/metrics.png'

import * as iconSell from '../images/icon-sell.png'
import * as iconPack from '../images/icon-pack.png'
import * as iconKonsum from '../images/icon-konsum.png'

const Features = () => {
  return (
    <React.Fragment>
      <MainHeadline>Deine Vorteile mit <strong>HeyPixie</strong></MainHeadline>
      <Feature previewImg={selling} iconImg={iconSell}>
        <Headline>Smart verkaufen &amp; verleihen.</Headline>
        <List>
          <ListItem>
            <strong>Verkaufe Dinge</strong>, die du nicht mehr brauchst, über Portale
            wie eBay, Momox, Kleiderkreisel und viele mehr
          </ListItem>
          <ListItem>          
            <strong> Vergleiche Wiederverkaufspreise</strong> von Portalen für deinen besten Preis 
            & <strong>erstelle Anzeigen</strong> direkt aus der App
          </ListItem>
          <ListItem>
            <strong>Organisiere Dinge</strong>, die du verleihen willst wie Bohrmaschine, Koffer usw., 
            in Listen und teile sie mit deinen Kontakten
          </ListItem>
        </List>
      </Feature>
      <Feature isRight previewImg={manageLists} iconImg={iconPack}>
        <Headline>Von überall für Reisen packen.</Headline>
        <List>
          <ListItem>
            <strong>Sammle in einer Packliste</strong> alle Dinge, 
            die du mit auf deine Reise nehmen willst - 
            von überall und zwischendurch
          </ListItem>
          <ListItem>
            <strong>Finde alle deine Gegenstände</strong> schnell und gezielt 
            mit unserer HeyPixie Suche und erhalte individuelle Pack-Vorschläge 
            für dein Reiseziel & deine Vorlieben
          </ListItem>
        </List>
      </Feature>
      <Feature previewImg={metrics} iconImg={iconKonsum}>
        <Headline>Für besseren Konsum.</Headline>
        <List>
          <ListItem>
            <strong>Habe deinen Konsum immer im Blick</strong>, 
            um dein Verhalten zu überdenken und bei Bedarf anzupassen
          </ListItem>
          <ListItem>
            <strong>Reduziere Fehlkäufe, spare Geld 
            und nutze Gegenstände nachhaltig</strong> - für ein zeitgemäßes Konsumverhalten
          </ListItem>
        </List>
      </Feature>
    </React.Fragment>
  )
}

const MainHeadline = styled.h2`
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
  margin-top: 64px;
  margin-bottom: 16px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    text-align: center;
  }
`

export default Features
