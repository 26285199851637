import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { DARK_BLUE, WRAPPER_WIDTH, TABLET_MIN_WIDTH } from './config'
import Phone from './Phone'
import singleItem from '../images/screenshots/single_item.png'
import PrimaryButton from './PrimaryButton'

const Header = () => {
  return (
    <OuterWrapper>
      <Wrapper>
        <LeftColumn>
          <Headline>
            Digitalisiere deine Gegenstände für smartes Verkaufen &amp; Verleihen!
          </Headline>
          <Text>
            Mit der <strong>HeyPixie App</strong> digitalisierst du deine Gegenstände 
            und holst so das Meiste aus deinem Zeug. Denn Verkaufen, Verleihen und sogar 
            Packen für Reisen wird damit smart und einfach.
          </Text>
          <PrimaryButton to='/app'>
            Zur App
          </PrimaryButton>
        </LeftColumn>
        <RightColumn>
          <PhoneWrapper>
            <Phone isGrey image={singleItem} />
          </PhoneWrapper>
        </RightColumn>
      </Wrapper>
    </OuterWrapper>
  )
}

const OuterWrapper = styled.header`
  background-color: ${DARK_BLUE};
`

const Wrapper = styled.div`
  max-width: ${WRAPPER_WIDTH}px;
  box-sizing: border-box;
  padding: 72px 32px 100px 32px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 auto;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    display: block;
    padding: 32px 24px 64px 24px;
    text-align: center;
  }
`

const LeftColumn = styled.div`
  width: 50%;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
    margin-bottom: 32px;
  }
`

const RightColumn = styled.div`
  width: 50%;
  text-align: right;
  margin-bottom: -100px;
  overflow: hidden;
  position: relative;
  margin-top: -32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
    margin-bottom: -64px;
    margin-top: 0;
  }
`

const PhoneWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    position: static;
  }
`

const Headline = styled.h1`
  font-size: 52px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    font-size: 36px;
    line-height: 44px;
  }
`

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 56px;
  strong {
    font-weight: 700;
  }
`

export default Header
