import React, { useState } from "react"
import styled from "styled-components"

import { DARK_BLUE, WRAPPER_WIDTH, GREY_95, TABLET_MIN_WIDTH, GREEN, GREY_40 } from './config'
import Phone from './Phone'

const FAQ = () => {
  const [showAllFAQs, setshowAllFAQs] = useState(false)

  return (
    <OuterWrapper>
      <Wrapper>
        <MainHeadline>FAQ</MainHeadline>
        <Items>
          <FaqItem>
            <Question>Was bedeutet für uns besserer Konsum?</Question>
            <Answer>
              HeyPixie hilft dir dein Konsumverhalten zu verbessern. Für uns heißt 
              ein besserer Konsum, mehr gebrauchte und weniger neue Produkte zu kaufen 
              und Dinge, die man nicht mehr braucht, weiterzuverkaufen. Mit HeyPixie 
              bieten wir dir ein Tool, das es dir leichter macht, deine Gegenstände 
              wieder in den Wirtschaftskreislauf zu geben, anstatt die Dinge einfach 
              wegzuwerfen - sofern sie noch nutzbar sind. Außerdem heißt ein besserer 
              Konsum für uns ein bewussterer Konsum. Mit der HeyPixie App fällt es 
              dir leichter, deinen Konsum zu überblicken und zu bewerten.
            </Answer>
          </FaqItem>
          <FaqItem>
            <Question>Was ist ein digitales Inventar?</Question>
            <Answer>
              Ein Inventar ist eine Auflistung aller Dinge, die zu einer bestimmten 
              Sache gehören. Mit HeyPixie bieten wir eine Anwendung für Privatpersonen 
              und deswegen dreht es sich bei uns um alle Gegenstände, die dir gehören. 
              Wir helfen dir mit HeyPixie eine solche Auflistung anzulegen, alle wichtigen 
              Informationen zum Gegenstand zu dokumentieren (z.B. Kaufdatum, Preis) und diese 
              für dich digital verfügbar zu machen.
            </Answer>
          </FaqItem>
          <FaqItem>
            <Question>Ist HeyPixie kostenlos?</Question>
            <Answer>
              HeyPixie befindet sich in der Entwicklung und ist daher für User/innen kostenlos. Unsere beste 
              Entlohnung ist derzeit, von euch Feedback zu bekommen. Parallel bereiten wir uns auf eine 
              Finanzierung vor, die uns helfen wird, HeyPixie marktreif zu machen.
            </Answer>
          </FaqItem>
          <FaqItem>
            <Question>Was macht HeyPixie mit deinen Daten?</Question>
            <Answer>
              Wir nutzen für die App eine SSL-Verbindung und europäische Server. Deine personenbezogenen Daten
              sowie Informationen über dein Inventar oder dein Konsumverhalten werden nicht an Dritte   
              weitergegeben. Die Informationen werden ausschließlich für dich zum besseren Verständis deines  
              eigenen Konsumverhaltens visuell aufbereitet. Dein persönliches Inventar wird nicht genutzt, 
              um dir personalisierte Werbung anzuzeigen.
            </Answer>
          </FaqItem>
          <FaqItem>
            <Question>Was nützt es mir, meine Gegenstände zu digitalisieren?</Question>
            <Answer>
              Mit den Gegenständen in deinem individuellen digitalen Inventar kannst du 
              andere Services (z.B. Weiterverkauf bei eBay) einfacher und smarter nutzen. 
              Außerdem erhältst du einen Überblick über dein Zeug, was dir helfen kann, 
              bessere Konsumentscheidungen zu treffen.
            </Answer>
          </FaqItem>
           <FaqItem>
            <Question>Wie kann ich Gegenstände erfassen?</Question>
            <Answer>
              Die Gegenstände, die du mit der HeyPixie App erfasst, bilden die Grundlage 
              für die Nutzung unserer App-Funktionen. Wir bieten der vier Möglichkeiten, 
              um Gegenstände zu deinem digitalen Inventar hinzuzufügen. Um die benötigten 
              Informationen automatisch zum Gegenstand zu erhalten, kannst du: (1) Gegenstand 
              fotografieren, (2) die URL einer Website mit dem Gegenstand eingeben, (3) den 
              Kaufbeleg abfotografieren, (4) die Bestätigungsmail deines Online-Kaufs an 
              HeyPixie weiterleiten.
            </Answer>
          </FaqItem>
        </Items>
        {!showAllFAQs && <MoreButton onClick={() => setshowAllFAQs(true)}>Alle FAQs ansehen</MoreButton>}
        {showAllFAQs && <Items>
          <FaqItem>
            <Question>Wie funktioniert der Weiterverkauf meiner Gegenstände?</Question>
            <Answer>
              Mit der HeyPixie App kannst du deine erfassten Gegenstände digital verwalten. 
              Solltest du dich aber einmal dafür entschieden, dich von einem oder mehreren 
              Gegenständen zu trennen, helfen wir dir diese einfach, smart und zum besten Preis 
              im Internet weiterzuverkaufen. Die HeyPixie App schätzt den Verkaufswert deines 
              Gegenstandes oder deiner Gegenstände auf unterschiedlichen Verkaufsportalen (z.B. 
              eBay Kleinanzeigen) ein und stellt dir sämtliche Informationen über deinen Gegenstand 
              zur Verfügung (z.B. Foto, Beschreibung, Kaufdatum), sodass du in sekundenschnelle 
              eine Anzeige anlegen kannst.
            </Answer>
          </FaqItem>
          <FaqItem>
            <Question>Wie funktioniert das Verleihen meiner Gegenstände?</Question>
            <Answer>
              In der HeyPixie App kannst du Listen anlegen. Listen eignen sich für das Organisieren 
              unterschiedlichster Anlässe - so auch für das Verleihen. Alle Gegenstände, die du verleihen 
              möchtest, ordnest du einer Liste zu. Diese Liste kannst du mit den Kontakten teilen, mit denen 
              du deine Gegenstände zukünftig teilen würdest. Hat ein Kontakt Interesse, kann sich dieser 
              direkt mit dir in Verbindung setzen.
            </Answer>
          </FaqItem>
          <FaqItem>
            <Question>Welche Verkaufsportale unterstützt HeyPixie?</Question>
            <Answer>
              HeyPixie nutzt für den Vergleich der Wiederverkaufspreise deiner zum Verkauf freigegebenen 
              Gegenstände und für das Generieren von Anzeigen die beliebtesten Online-Verkaufsportale. Das 
              sind vor allem die, bei denen es eine angemessene Nutzeranzahl, gute Preise, eine hohe An- bzw. 
              Verkaufswahrscheinlichkeit und ein leichtes Handling gibt. Derzeit sind das eBay, Kleiderkreisel, 
              Zalando Wardrobe, Momox, ReBuy und WirKaufens. Wir bewerten diese Portale kontinuierlich 
              und bieten dir den smartesten Weg, um dein Zeug weiterzuverkaufen.
            </Answer>
          </FaqItem>
          <FaqItem>
            <Question>Wird der Weiterverkauf über HeyPixie abgewickelt?</Question>
            <Answer>
              Nein, wir bieten dir mit der HeyPixie App ausschließlich eine Einschätzung des Verkaufspreises 
              auf dem jeweiligen Portal (z.B. eBay Kleinanzeigen) und stellen dir Informationen über deinen 
              Gegenstand, den du verkaufen möchtest, bereit. Die Anzeige muss auf dem jeweiligen Portal direkt 
              angelegt werden, was fast immer die Erstellung eines Accounts auf eigenem Namen erfordert.
            </Answer>
          </FaqItem>
          <FaqItem>
            <Question>Wie funktioniert die HeyPixie-Datenanalyse?</Question>
            <Answer>
              Beim Anlegen deiner Gegenstände, beim Kaufen oder Verkaufen entstehen unterschiedliche Daten, 
              die dir helfen können, dein Konsumverhalten besser zu verstehen. Dazu zählen, wie viele Teile 
              du gebraucht gekauft hast, wie viele Teile du weiterverkauft hast, wie viele Gegenstände du 
              besitzt, in welchen Kategorien du besonders wenige oder viele Gegenstände besitzt, welche 
              Gegenstände du doppelt hast und vieles mehr. Diese Informationen bereitet HeyPixie übersichtlich 
              und nachvollziehbar für dich in der App auf.
            </Answer>
          </FaqItem>
          <FaqItem>
            <Question>Wo gibt es die App? </Question>
            <Answer>
              Die HeyPixie App wird derzeit von unserem Team entwickelt und wird nach Fertigstellung auf 
              dieser Website verfügbar sein. Um besser zu verstehen, vor welchen Problemen unsere potentiellen 
              Usern und Userinnen tatsächlich stehen, haben wir diese für Validierungszwecken angelegt.
            </Answer>
          </FaqItem>
         </Items>}
        {showAllFAQs && <MoreButton onClick={() => setshowAllFAQs(false)}>Weniger FAQs</MoreButton>}
      </Wrapper>
    </OuterWrapper>
  )
}

const MoreButton = styled.button`
  outline: none;
  margin: 0 auto;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: ${DARK_BLUE};
  font-family: 'Muli', sans-serif;
  cursor: pointer;
  background-color: transparent;
  line-height: 18px;
  padding: 8px 16px;
  margin-top: 24px;
  border: 1px solid ${DARK_BLUE};

  &:hover {
    color: ${GREEN};
    border-color: ${GREEN};
  }
`

const OuterWrapper = styled.section`
  background-color: ${GREY_95};
`

const Wrapper = styled.div`
  max-width: ${WRAPPER_WIDTH}px;
  box-sizing: border-box;
  padding: 40px 24px;
  margin: 16px auto 0 auto;
  text-align: center;
`

const MainHeadline = styled.h2`
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    text-align: center;
  }
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    display: block;
  }
`

const FaqItem = styled.div`
  margin: 12px;
  box-sizing: border-box;
  width: calc(50% - 24px);
  background-color: white;
  border-radius: 3px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.2);
  padding: 24px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
    margin: 16px 0;
  }
`

const Question = styled.h3`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 18px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    font-size: 13px;
    line-height: 18px;
  }
`

const Answer = styled.p`
  color: ${GREY_40};
  font-size: 15px;
  line-height: 20px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    font-size: 13px;
    line-height: 18px;
  }
`

export default FAQ
