import React from "react"
import styled from "styled-components"

import { WRAPPER_WIDTH, GREY_90, TABLET_MIN_WIDTH } from './config'
import Phone from './Phone'

const Feature = ({ children, previewImg, isRight, iconImg }) => {
  return (
    <OuterWrapper>
      <Wrapper>
        <TextColumn>
          <Icon src={iconImg} />
          {children}
        </TextColumn>
        <Preview isLeft={isRight}>
          <Phone showBlock image={previewImg} />
        </Preview>
      </Wrapper>
    </OuterWrapper>
  )
}

const OuterWrapper = styled.section`
`

const Wrapper = styled.div`
  max-width: ${WRAPPER_WIDTH}px;
  box-sizing: border-box;
  padding: 32px 0;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    display: block;
    padding: 16px 0;
  }
`

const TextColumn = styled.div`
  width: 60%;
  padding: 0 32px;
  box-sizing: border-box;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
    padding: 16px 24px;
    text-align: center;
  }
`

const Preview = styled.div`
  padding: 0 32px;
  box-sizing: border-box;
  margin-top: -32px;
  width: 40%;
  ${({ isLeft }) => isLeft && `order: -1;`}
  padding-left: ${({ isLeft }) => isLeft ? 32 : 96}px;
  padding-right: ${({ isLeft }) => isLeft ? 96 : 32}px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    width: 100%;
    padding: 16px 24px;
    margin-top: 0;
  }
`

export const Headline = styled.h3`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    text-align: center;
  }
`

export const List = styled.ul`
  font-size: 18px;
  line-height: 28px;
  text-align: left;
`

export const ListItem = styled.li`
  margin-bottom: 16px;
  padding-left: 64px;
  position: relative;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    padding-left: 32px;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    border: 4px solid ${GREY_90};
    left: 32px;
    top: 6px;

    @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
      left: 0;
    }
  }
`

const Icon = styled.img`
  width: 120px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    margin: 0 auto;
  }
`

export default Feature
